/* * Codecraft Styles 2019-06-07 */
/* You can add global styles to this file, and also import other style files */

:root {
  --apache: #c738b8;
  --firewall: #ed1c26;
  --cyberyellow: #ffb000;
  --dreamweaver: #9fd13d;
  --python: #45a64a;
  --proxy: #17a3b8;
  --ethernet: #009ee0;
  --boolean: #0563f0;
  --cyberspace: #211c38;
  --gear: #bdbac2;
}

@font-face {
  font-family: bonum;
  src: url(/assets/font/texgyrebonum-regular.otf) format('opentype');
}

@font-face {
  font-family: bonum-bold;
  src: url(/assets/font/texgyrebonum-bold.otf) format('opentype');
}

@font-face {
  font-family: bonum-bolditalic;
  src: url(/assets/font/texgyrebonum-bolditalic.otf) format('opentype');
}

@font-face {
  font-family: avenir-roman;
  src: url(/assets/font/AvenirLTStd-Roman.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: avenir-roman-black;
  src: url(/assets/font/AvenirLTStd-Black.otf) format('opentype');
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: avenir-roman-book;
  src: url(/assets/font/AvenirLTStd-Book.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

:root {
  --font-family-sans-serif: avenir-roman, Roboto, 'Helvetica Neue', Arial,
    -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --font-family-serif: bonum-bold, 'Georgia', 'Times New Roman', 'Times', serif;
  --font-family-monospace: 'Roboto Mono', SFMono-Regular, Menlo, Monaco,
    Consolas, 'Liberation Mono', 'Courier New', monospace;
}

body {
  font-family: var(--font-family-sans-serif);
  color: var(--primary);
}

a {
  color: var(--primary);
  text-decoration: underline;
}

a.btn {
  text-decoration: underline;
}

a:hover {
  color: var(--primary);
  text-decoration: underline wavy 1px;
}

pre,
code,
kbd,
samp {
  font-family: var(--font-family-monospace);
  font-size: 1em;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--font-family-serif, bonum-bold);
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-top: var(--primary) 2px solid;
}

.nav-item > a {
  background-color: var(--light) !important;
}

.nav-item > a.active {
  background-color: var(--primary) !important;
  color: var(--light) !important;
}

.ag-theme-material .ag-header {
  /* use theme parameters where possible */
  --ag-header-background-color: var(--primary);
  --ag-header-foreground-color: var(--light);
  --ag-font-size: 1rem;
}

.ag-theme-material .ag-header-cell-label {
  font-size: 1rem;
}

.ag-theme-material .ag-root-wrapper {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

.ag-theme-material .ag-header-active .ag-column-hover,
.ag-header-cell:hover .ag-header-cell-text {
  color: var(--primary);
}


.ag-theme-material .ag-row {
  font-size: 1rem !important;
}

.instructor-certificate {
  color: var(--light);
}

.wp-block-codecraft-learndash-firebase-plugin-instructor-notes::before {
  content: 'Instructor Notes';
  display: block;
  font-weight: bolder;
  font-size: 1rem;
  text-align: center;
  color: var(--light);
  background: var(--primary);
}

.wp-block-codecraft-learndash-firebase-plugin-instructor-notes {
  padding: 1rem;
}

.wp-block-codecraft-learndash-firebase-plugin-instructor-notes::after {
  display: block;
  content: '';
  background-color: var(--primary);
  border-bottom: 4px solid var(--primary);
}

bs-datepicker-container {
  z-index: 9999 !important;
}

.form-group > label {
  font-weight: 900;
  font-size: 1.5em;
}

.tooltip-wrapper {
  display: inline-block;
  float: left;
}

.tooltip-wrapper .btn {
  border-radius: 0;
}

.tooltip-wrapper:first-child .btn {
  border-radius: 3px 0 0 3px;
}

.tooltip-wrapper:last-child .btn {
  border-radius: 0 3px 3px 0;
}

.sortable-item {
  padding: 6px 12px;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 1.4em;
  text-align: center;
  cursor: grab;
  border: 1px solid transparent;
  border-radius: 4px;
  border-color: #adadad;
}
.sortable-item-active {
  background-color: #3498db;
  border: none;
  color: #fff;
}

.projectFrame {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
  width: 100%;
}

footer {
  margin-top: auto;
  color: white;
  font-size: 0.95rem;
  line-height: 1.7;
}

footer a:visited {
  color: white !important;
}

footer a:link {
  color: white !important;
}

app-root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.card-footer > h1,
.card-footer > h2,
.card-footer > h3,
.card-footer > h4,
.card-footer > h5,
.card-footer > h6 {
  margin-bottom: 0em !important;
}

.card {
  border: 0px !important;
}

.lead {
  font-size: 1.5rem;
  font-weight: 300;
}

@media (max-width: 575px) {
  h1,
  .h1 {
    font-size: 2rem;
  }

  h2,
  .h2 {
    font-size: 1.75rem;
  }

  h3,
  .h3 {
    font-size: 1.5rem;
  }

  h4,
  .h4 {
    font-size: 1.25rem;
  }

  h5,
  .h5 {
    font-size: 1.125rem;
  }

  h6,
  .h6 {
    font-size: 1rem;
  }

  .display-1 {
    font-size: 4rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-2 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-3 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-4 {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
}

@media (min-width: 576px) {
  h1,
  .h1 {
    font-size: 2.5rem;
  }

  h2,
  .h2 {
    font-size: 2.25rem;
  }

  h3,
  .h3 {
    font-size: 2rem;
  }

  h4,
  .h4 {
    font-size: 1.75rem;
  }

  h5,
  .h5 {
    font-size: 1.5rem;
  }

  h6,
  .h6 {
    font-size: 1.25rem;
  }

  .display-1 {
    font-size: 5rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-2 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-3 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-4 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
}

@media (min-width: 768px) {
  h1,
  .h1 {
    font-size: 3rem;
  }

  h2,
  .h2 {
    font-size: 2.5rem;
  }

  h3,
  .h3 {
    font-size: 2rem;
  }

  h4,
  .h4 {
    font-size: 1.5rem;
  }

  h5,
  .h5 {
    font-size: 1.25rem;
  }

  h6,
  .h6 {
    font-size: 1.25rem;
  }

  .display-1 {
    font-size: 4rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-2 {
    font-size: 3rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-3 {
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.2;
  }

  .display-4 {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.2;
  }
  .display-5 {
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.2;
  }
}

@media (min-width: 1920px) {
  .container-fluid {
    margin-left: 0px;
  }
}

.dark-shadow {
  box-shadow: 5px 5px 10px 5px rgba(33, 29, 56, 0.5);
}

.container-fluid {
  max-width: 1920px;
}

.sold-out-img-overlay {
  z-index: 1000;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
}

div.ngtt-se__round-wrapper:nth-child(odd) {
  background-color: var(--light);
}

div.ngtt-se__round-wrapper:nth-child(odd) .matchup > span {
  background-color: white;
}

tabset {
  display: block;
}

tab > app-grid > div.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.alert {
  color: var(--primary);
}

.alert-primary,
.alert-dark,
.alert-danger {
  color: var(--light);
}

code {
  color: var(--primary);
  background-color: var(--light);
}

.CodeMirror,
.CodeMirror-gutters,
#blocklyOutput > pre,
#blocklyOutput > pre > code {
  color: var(--light);
  background-color: var(--primary) !important;
}

.as-vertical > .as-split-gutter {
  border-top: var(--light) 1px dotted !important;
  border-bottom: var(--light) 1px dotted !important;
}

.as-horizontal > .as-split-gutter {
  border-left: var(--light) 1px dotted !important;
  border-right: var(--light) 1px dotted !important;
}

b,
strong {
  font-family: 'avenir-roman-black';
  font-weight: normal;
  font-style: normal;
}

em,
i {
  font-family: 'avenir-roman-book';
  font-weight: normal;
  font-style: italic;
}

blockquote,
.blockquote {
  font-family: 'avenir-roman-book';
  font-weight: normal;
  font-style: normal;
  margin-inline-start: 2rem;
  margin-block: 1rem;
  padding: 2rem;
  border-inline-start: var(--primary) 5px solid;
}

modal-container {
backdrop-filter: blur(5px);
}

